// Dark theme
@use "@angular/material" as mat;
@use "../theme/variables.scss" as v;
@import "./rina-colors.scss";

$dark-theme-primary: mat.define-palette(
  $rina-darkgray,
  50,
  100,
  500,
  900
); // most widely used across all screen and components
$dark-theme-accent: mat.define-palette(
  $rina-blue,
  50,
  100,
  500,
  900
); // floating action color and interactive element
$dark-theme-foreground: mat.define-palette(
  $rina-lightblue,
  50,
  100,
  500,
  900
); // for text and icons
$dark-theme-warn: mat.define-palette($rina-warn, 50, 100, 500, 900);
$dark-theme: mat.define-dark-theme(
  $dark-theme-primary,
  $dark-theme-accent,
  $dark-theme-foreground
);

@function color-get($map, $key, $opacity) {
  // input palette, color number, opacity  --> output rgba
  $value: map-get($map, $key);
  @return rgba($value, $opacity);
}

.dark-theme {
  .drawer {
    background-image: linear-gradient(rgba(35, 31, 32, 1), rgb(66, 66, 66));
  }

  .sidenav {
    background-image: linear-gradient(rgba(35, 31, 32, 1), rgb(66, 66, 66));
  }

  .mat-toolbar.page-toolbar {
    @extend %page-toolbar;
  }

  %page-toolbar {
    background: color-get($dark-theme-primary, 900, 1);
    color: white;
    font-weight: 400;
    height: 55px;
    position: sticky;
    top: 0;
    z-index: 3;

    .asset-name {
      font-size: 18px;
      padding-right: 16px;
    }

    .border-right {
      border-right: 1px solid white;
    }

    .page-name {
      font-size: 18px;
      padding-left: 16px;
    }

    .link-icon {
      padding-right: 16px;

      &.color-warning {
        color: Red;
      }

      &.color-wifi_off {
        color: Orange;
      }

      .mat-icon {
        line-height: 32px;
      }
    }

    mat-nav-list mat-list-item,
    mat-list mat-list-item {
      color: white;

      &.active {
        color: color-get($dark-theme-accent, 500, 1);
      }
    }
  }

  %rina-text {
    color: mat.get-color-from-palette($rina-darkblue, 500, 0.7);

    &:hover {
      color: mat.get-color-from-palette($rina-darkblue, 500, 1);
    }
  }

  .mat-mdc-tab-label.mat-mdc-tab-label-active {
    color: color-get($rina-lightblue, 500, 1);
  }

  .toolbar-user-container,
  .toolbar-notification-container {
    .dropdown {
      background: color-get($dark-theme-primary, 900, 1) !important;
    }

    .header {
      background: color-get($dark-theme-primary, 900, 1) !important;
      color: color-get($dark-theme-primary, 50, 1) !important;
      border-bottom: 1px solid color-get($dark-theme-primary, 500, 1) !important;
    }

    .footer {
      border-top: 1px solid color-get($dark-theme-primary, 500, 1) !important;
    }
  }

  cube-details-tab {
    .details-container {
      background: linear-gradient(
          rgba(66, 66, 66, 0.95),
          rgba(66, 66, 66, 0.95)
        ),
        url("/../assets/map/wind/blades_landscape.png") repeat-x fixed 10px
          bottom;
    }
  }

  cube-user-dialog {
    .user-list-select-avatar {
      border: solid 1px #7b8181;
    }
  }

  cube-upload-defect-files-dialog,
  cube-upload-file-dialog {
    .list-files {
      color: white;
    }
  }

  .mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background {
    background-color: white !important;
    border-color: white !important;
  }

  .mat-mdc-checkbox-indeterminate.mat-accent .mdc-checkbox__background {
    background-color: white !important;
  }

  .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    color: #303030 !important;
  }

  .card-content-text-color {
    color: #ffffff !important;
  }

  .chat-action-bar {
    background: #303030 !important;
  }

  .mat-mdc-form-field-label .mat-mdc-form-field-required-marker {
    color: #ff2c00;
  }

  .floating_nav {
    .mat-mdc-text-field-wrapper {
      background-color: #424242;
    }

    .mdc-notched-outline__leading {
      border: none !important;
    }

    .mdc-notched-outline__trailing {
      border: none !important;
    }
  }

  .example-card {
    background: #424242;
    color: #ffffff;
  }

  .header-toolbar {
    background-color: #424242;
  }

  .footer {
    background-color: #424242;
    border-top: 1px solid #424242;
    bottom: 0 !important;
    padding: 16px 24px;
    position: sticky !important;
    transition: opacity 0.367s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s;
    z-index: 2;
  }

  .wave .dot {
    background: white;
  }

  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    font-weight: 700;
    color: white !important;
    opacity: 1;
  }

  .delete-button {
    background-color: #a8a6a6;
  }

  .delete-button.mat-mdc-outlined-button:not(:disabled) {
    background-color: #a8a6a6;
    color: red;
  }

  .close-arrow {
    background-color: #424242;
  }
  
  .tab-container-section {
    background-color: #424242;
  }
}
