@use "@angular/material" as mat;

@mixin mat-list-icon-theme($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
}

@mixin mat-chip-theme() {
  padding: 1px 7px;
}

@mixin sidemenu-item-theme($theme, $icon-size) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .sidenav-dropdown-indicator {
    transition: transform 0.25s;

    &.indicateOpen {
      transform: rotate(180deg);
    }
  }
  mat-nav-list {
    overflow: hidden;
    padding-top: 0;
  }
  .primary {
    transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    padding-top: 0;
  }
  .secondaryMenu {
    background: mat.get-color-from-palette($primary, 300);
    transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    padding-top: 0;
  }

  .mat-mdc-nav-list .mat-mdc-list-item .mat-list-icon {
    @include mat-list-icon-theme($icon-size);
  }
  .mat-chip:not(.mat-basic-chip) {
    @include mat-chip-theme();
  }
  .mat-mdc-nav-list .mat-mdc-list-item {
    border-radius: initial;
    font-size: 14px;
  }
  .active {
    border-left: 3px solid #fff;
  }
  mat-icon {
    font-size: 20px;
    margin-top: 4px;
  }
}
