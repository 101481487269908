/* You can add global styles to this file, and also import other style files */
@use "src/theme/variables" as v;
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
// @import "~font-awesome";

body * {
  scrollbar-width: none;
}

.header-toolbar {
  display: flex;
  gap: 30px;
  padding: 6px;
  position: sticky !important;
  top: 50px !important;
  z-index: 1 !important;
}

.mat-form-field.mat-paginator-page-size-select .mat-form-field-wrapper {
  padding-bottom: 6px;
}

// Dialog
mat-dialog-container.mat-dialog-container {
  overflow: unset;
  padding: 0;
  position: relative;

  .close-icon {
    height: 30px;
    line-height: 20px;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;

    .mat-icon {
      font-size: 20px;
      height: 20px;
      line-height: 20px;
      width: 20px;
    }
  }

  $padding-block: 25px;
  $padding-inline: min(5vw, 35px);

  .mat-dialog-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0.75rem;
    padding-block: $padding-block 0;
    padding-inline: $padding-inline;
  }

  .mat-dialog-content {
    margin: 0;
    max-height: 75vh;
    padding-block: 0.5rem $padding-block;
    padding-inline: $padding-inline;
  }
}

.dialog-form {
  display: grid;
  gap: 2px 10px;
  grid-auto-flow: row dense;
  grid-template-columns: 1fr;

  .action-buttons {
    display: flex;
    gap: 15px;
    grid-column: 1 / -1;
    justify-self: end;
  }
}

.col-span-2 {
  grid-column-end: span 2;

  @media #{v.$lt-sm} {
    grid-column-end: unset;
  }
}

.mat-mdc-tab.mdc-tab  {
  font-size: 16px;
  font-weight: 500;

  &.mdc-tab--active {
    font-weight: 600;
  }
}

.mat-mdc-tab-body-wrapper {
  height: 100%;
  margin-block-start: 10px;
}

.mat-form-field-disabled .mat-form-field-flex {
  cursor: not-allowed;

  * {
    cursor: not-allowed;
  }
}

table {
  word-break: break-word;
}

p-speeddial .p-speeddial {
  z-index: 9999;
}

.p-speeddial-mask {
  z-index: 999;
}

.serial-number {
  background: #d1dbfa;
  border-radius: 3px;
  color: #123bb6;
  font-size: 12px;
  margin-left: 3px;
  padding: 0 3px;
}

::-webkit-scrollbar {
  overflow-y: scroll;
  display: none;
}

.clear-button {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.errorMessage {
  background-color: #fcadad !important;
  color: red !important;
  .mat-simple-snackbar-action {
    color: black !important;
  }
}

.dark-theme {
  .dialogTitle{
    color: white;
  }
}
